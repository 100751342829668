/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-alert',
  template: `
    <span kendoTypography fontSize="md">{{_msg}}</span>
  `,
  styles: [],
})
export class DynamicAlertComponent {
  protected _msg: string | undefined;


  @Input()
  set msg(content: string) {
    this._msg = content;
  }

}

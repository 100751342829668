/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { type ComponentRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { type Animation, type NotificationRef, NotificationService, type NotificationSettings, type Position, type Type as AlertType } from '@progress/kendo-angular-notification';
import { map } from 'rxjs/operators';
import { DynamicAlertComponent } from '@core/components/dynamic-alert/dynamic-alert.component';
import { defaultsDeep } from 'lodash-es';

export type AlertSettings = Omit<NotificationSettings, 'content'>

export const defaultPosition: Position = { horizontal: 'left', vertical: 'bottom' };
export const defaultAnimation: Animation = { type: 'fade', duration: 1000 };

export const defaultAlertSettings: AlertSettings = {
  type: {
    style: 'none',
    icon: true,
  },
  animation: defaultAnimation,
  position: defaultPosition,
};

export function alertType(type: AlertType['style'], closable = false): AlertSettings {
  return {
    ...defaultAlertSettings,
    type: {
      style: type,
    },
    closable: closable,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  private displayedMessages = new Map<string, NotificationRef>();


  constructor(private notification: NotificationService, private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      for (const [key, ref] of this.displayedMessages.entries()) {
        this.translate.get(key).subscribe((msg: string) => {
          const content = ref.content as ComponentRef<DynamicAlertComponent>;
          content.instance.msg = msg;
        });
      }
    });
  }


  public getAlertMatching(searchKey: string) {
    for (const key of this.displayedMessages.keys()) {
      if (key.includes(searchKey)) {
        return this.displayedMessages.get(key);
      }
    }
    return undefined;
  }

  public isAlertShown(containsKey: string) {
    for (const key of this.displayedMessages.keys()) {
      if (key.includes(containsKey)) {
        return true;
      }
    }
    return false;
  }

  public hideAlertsMatching(searchKey: string) {
    for (const key of this.displayedMessages.keys()) {
      if (key.includes(searchKey)) {
        const ref = this.displayedMessages.get(key);
        ref?.hide();
        return ref?.afterHide;
      }
    }
    return undefined;
  }

  public showBackendUnavailableAlert(): NotificationRef {
    const refKey = 'core.alert.unavailable';
    const defaultMsg = 'Service unavailable, please try again later. We apologise for the inconvenience.';
    const settings: AlertSettings = {
      type: {
        style: 'error',
        icon: false,
      },
      animation: defaultAnimation,
      position: defaultPosition,
      closable: true,
    };
    return this.showAlertIfNotShown(refKey, { defaultMsg, settings });
  }

  public showBackendAvailableAlert(): NotificationRef {
    const key = 'core.alert.available';
    const defaultMsg = 'Service is back online.';
    const settings: AlertSettings = {
      type: {
        style: 'success',
        icon: false,
      },
      animation: defaultAnimation,
      position: defaultPosition,
      hideAfter: 5000,
    };
    return this.showAlertIfNotShown(key, { defaultMsg, settings });
  }

  public showAlertIfNotShown(i18nKey: string, options: { defaultMsg?: string, interpolateParams?: Record<string, unknown>, settings?: Partial<AlertSettings> } = {}) {
    options.settings ??= defaultAlertSettings;
    defaultsDeep(options.settings, defaultAlertSettings);
    options.defaultMsg ??= '';

    const existing = this.displayedMessages.get(i18nKey);
    if (existing) {
      return existing;
    }

    const ref = this.notification.show({
      ...options.settings,
      content: DynamicAlertComponent,
    });

    this.translate.get(i18nKey, options.interpolateParams).pipe(
      map((msg: string) => msg === i18nKey ? options.defaultMsg! : msg),
    ).subscribe((msg: string) => {
      if (ref) {
        const content = ref.content as ComponentRef<DynamicAlertComponent>;
        content.instance.msg = msg;
      }
    });

    ref.afterHide?.subscribe(() => this.displayedMessages.delete(i18nKey));
    this.displayedMessages.set(i18nKey, ref);

    return ref;
  }
}
